

shopJS.ckShipping = {
    shippingListBox: $('.js-shipping-list'),
    shippingList : $('.js-shipping-list li'),
    shippingProtection : $('#shipping-protection'),
    shippingProtectionHelp : $('.js-protection .btn_question'),

    // shippingItemSelected: $('.js-shipping-list li.on'),

    selectedShipping : $('.js-selected-shipping'),

    init: function (){
        // in step 2
        shopJS.report.checkout({step: 2});
        shopJS.ckShipping.shippingListBox.on('click', 'li', function () {
            const $this = $(this);
            if (!$this.hasClass('disabled')) {
                shopJS.ckShipping.ajaxGetShippingFee($this);
            }
        });

        // shipping protection
        shopJS.ckShipping.shippingProtection.on('change', function () {
            shopJS.ckShipping.ajaxChooseShippingProtection(!!this.checked)
        })

        // shipping protection help
        shopJS.ckShipping.shippingProtectionHelp.on('click', function () {
            shopJS.common.alert("Get your order reshipped for free if it's lost or damaged during transit.");
        })
    },

    shippingFee : function(fee,fee_c,fee_original_c){
        const $shippingSummary = $('.js-summary-shipping-fee');
        return $shippingSummary.data('value');
    },

    ajaxGetShippingFee : function($element){
        let shippingKey = $element.data('value');
        let $shippingErrorMessage = $('.shipping-error-message');
        let address = shopJS.ckAddress.getAddress();

        if(!address){
            $shippingErrorMessage.html('Please select or input shipping address!');
            $shippingErrorMessage.removeClass('hide');
            return false;
        }else{
            $shippingErrorMessage.html('');
            $shippingErrorMessage.addClass('hide');
        }

        $u.http.post('/checkout/ajaxGetShippingFee',{
            rush_level : $('.js-rush-list li.on').data('value'),
            shipping_method : shippingKey,
            delivery_cid : address.delivery_cid,
            delivery_pid : address.delivery_pid !== undefined ? address.delivery_pid : 0,
            rush_fee : shopJS.ckRush.rushFee(),
        }).then(res=>{
            if(!res.data || !res.data.data){
                shopJS.common.alert('server error')
                return;
            }

            let data = res.data.data;

            if (res.data.code === 200 && data.method_checked && data.success && $element.data('disable') !== '1') {
                let html = '';
                if(data.shipping_fee_c !== data.shipping_fee_original_c) {
                    html += `<del>${data.shipping_fee_original_c}</del> &nbsp;`
                }
                html += data.shipping_fee_c
                $element.find('.js-shipping-fee').html(html);
                $element.find('.s_time').text(data.description);

                if(data.shipping_fee === 0){
                    $element.find('.js-free-shipping-tips').removeClass('hide');
                }else{
                    $element.find('.js-free-shipping-tips').addClass('hide');
                }

                if (data.disabled) {
                    $element.addClass('disabled');
                }else{
                    $element.removeClass('disabled');

                    shopJS.ckShipping.shippingList.removeClass('on');
                    $element.addClass('on').removeClass('hide');
                    $element.find('input').prop('checked', true);
                    shopJS.checkout.updateRush();
                    shopJS.checkout.getCartSummary();
                }
            }

            if(!data.method_checked || $element.data('disable') === '1'){
                $element.addClass('hide');
            }else {
                $element.removeClass('hide');
            }

            if( data.message && !data.success ){
                $element.find('input').prop('checked', false);
            }else{
                $shippingErrorMessage.html('');
                $shippingErrorMessage.addClass('hide');
            }
        }).catch()
    },

    getShipping : function(){
        let selectedShippingMethod = shopJS.ckShipping.shippingListBox.find('li.on');
        return selectedShippingMethod.length ? selectedShippingMethod.data('value') : undefined;
    },

    ajaxChooseShippingProtection(isChecked){
        $u.http.post('/checkout/ajaxChooseShippingProtection',{
            checked : isChecked,
        }).then(res=>{
            let result = res.data;
            if (result.code === 200 && result.data.success) {
                shopJS.checkout.getCartSummary();
            }
        }).catch()
    }
};