/*
 *
 * warning:This file in the last one when the mergers.
 *
 */

$(function () {
    /**
     *
     * @param {string} el  Feature detection - element
     * @param {function} fn  Executive function
     */
    function init(el,fn){
        if($(el).length && (typeof fn) === 'function') {
            fn.call(fn,el);
        }
    }

    new Promise(resolve=>{
        resolve()
    }).then(function(){

        shopJS.googleAds.googleAds();


        //index
        init('.p-idx',shopJS.index.init);

        //category
        init('.p-ctg',shopJS.category.init);
        init('.js-filter-modal',shopJS.filter.init);
        init('.prod-list img.prod-img,.favorite-list img.prod-img',shopJS.category.getImage);
        //init('#filter-modal',shopJS.filter.selectFilter);
        //init('.filter-box',shopJS.filter.removeFilterEvent);

        //product page.
        init('.p-prod',shopJS.prod.init);

        //reviewsx
        init('#write-review',shopJS.review.writeInit);
        init('.js-review-more', shopJS.review.reviewMore);
        init('.questions-box', shopJS.question.init);

        //cart & payment.
        init('.p-cart', shopJS.cart.init);
        init('.p-chk', shopJS.checkout.init);
        init('.p-payment', shopJS.payment.init);
        // init('#Valentine2021', shopJS.payment.valentine2021);
        init('.also-like-box', shopJS.cart.alsoLikeSelectProduct);
        init('.js-order-track', shopJS.orderTrack.init);
        init('.p-sces', shopJS.paySuccess.init);

        //sign-in & join-free
        init('.p-sign',shopJS.sign.signToggle);
        init('.p-sign',shopJS.sign.init);

        // size-chart
        init('#size-chart',shopJS.size.sizeToggle);

        //header
        init('header',shopJS.header.init);

        //footer
        init('footer',shopJS.footer.init);

        // common.
        init('body', shopJS.common.globalInit);
        init('.prod-list', shopJS.like.init);
        init('.js-currency', shopJS.common.changeCurrency);
        init('.js-email-subscribe', shopJS.script.emailSubscribe);
        init('.icon-top', shopJS.common.backToTop);
        init('.top-countdown', shopJS.common.countDown);
        init('#color-chart', shopJS.color.colorChart);
        init('.js-share', shopJS.common.share);
        init('.btn-go-back', shopJS.common.goBack);
        init('.modal', shopJS.common.modalRouter);
        init('.js-auto-copy',shopJS.common.autoCopy);
        init('.recommend-list',shopJS.category.getRecommendList);

        // report
        init('.ga4-prod-item', shopJS.common.bindClickItemInList);

        init('body', shopJS.static.commonStatic);
        init('header,footer', shopJS.static.baseStatic);

        //user
        init('.p-info', shopJS.user.info);
        init('.p-editadd', shopJS.user.addressEdit);
        init('.p-address', shopJS.user.address);
        init('.js-remove-all-history', shopJS.user.clearHistory);
        init('.js-remove-favorite',shopJS.user.removeFavorite);
        init('.new-user-fixed',shopJS.user.closeNewUserFixedTips);

        init('.p-flash-sale .ac-countdown',shopJS.flashSale.initFlashSaleCountdown);
        init('.p-vip',shopJS.vip.init);

        init('.global-message', shopJS.common.setGlobalMsg)
    })
});
