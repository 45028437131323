shopJS.ckAddress = {
    addressInput: {
        rid:                  $('#rid'),
        type:                 $('#address_type'),
        deliveryFirstName:    $('#first-name'),
        deliveryLastName:     $('#last-name'),
        deliveryAddress:      $('#address'),
        deliveryCity:         $('#city'),
        deliveryCid:          $('#country-id'),
        deliveryPid:          $('#province-id'),
        deliveryProvince:     $('#province'),
        deliveryPostcode:     $('#postal'),
        deliveryMobile:       $('#phone'),
        deliveryEmail:        $('#email'),
    },

    googleMapping: {
        locality: {
            formField: "city",
            valueField: "long_name"
        },
        administrative_area_level_1: {
            formField: "province",
            valueField: "long_name"
        },
        postal_code: {
            formField: "postal",
            valueField: "long_name"
        }
    },

    // auto complete address with google maps.
    autoCompleteOfMaps: null,

    ACResultTypesMap: {
        province:    ['administrative_area_level_1'],
        city:        ['locality'],
        postal_code: ['postal_code'],
        address:     ['street_number', 'route', 'sublocality_level_1']
    },

    countryCode: '',

    // selected
    addressSelected: $('.js-address-selected'),

    // form
    addressForm: $('.js-address-form'),
    addressEditModal: $('#addressEditModal'),
    addressBtnSave: $('.js-address-save'),

    // list
    addressList: $('.js-address-list'),
    addressListModal: $('#addressListModal'),
    addressBtnNew: $('.js-new-address'),
    addressBtnChooseConfirm: $('.js-conform-choose'),
    addressBtnEdit: $('.js-address-edit'),
    addressBtnChoose: $('.js-address-choose'),

    addressListModalCanBeClose: true,
    provinces:       [],

    address: null,
    init: function() {
        shopJS.ckAddress.loadGoogleMapJs();
        shopJS.ckAddress.initSelectedAddress();
        shopJS.ckAddress.initAddressListModal();
        shopJS.ckAddress.initAddressListEvents();
        shopJS.ckAddress.initAddressForm();
    },

    initForBilling: function(){
        shopJS.ckAddress.initSelectedAddress();
        shopJS.ckAddress.loadGoogleMapJs();
        shopJS.ckAddress.initAddressForm();
        shopJS.ckAddress.addressInput.type.val('billing')
        shopJS.ckAddress.addressForm.data('action','set-selected')
        shopJS.ckAddress.resetAddressShowEvents();
    },

    initSelectedAddress: function() {
        let defaultAddress = shopJS.ckAddress.addressSelected;
        defaultAddress.length > 0 && shopJS.ckAddress.setAddress(defaultAddress.find('.address-show').data('addressjson'))
    },

    initAddressListModal: function(){
        shopJS.ckAddress.addressListModal.on('show.bs.modal',function(e){
            shopJS.ckAddress.addressForm.data('action','update')
        });

        shopJS.ckAddress.addressListModal.on('hide.bs.modal', function (e) {
            if (shopJS.ckAddress.addressListModalCanBeClose === false) {
                e.preventDefault();
            }
            shopJS.ckAddress.addressListModalCanBeClose = true;
        })
    },

    initAddressListEvents:function(){
        shopJS.ckAddress.resetAddressListEvents();

        // create new address
        shopJS.ckAddress.addressBtnNew.on('click', function (e) {
            e.preventDefault();
            shopJS.ckAddress.setAddressInput();
            shopJS.ckAddress.addressModalShow();
            shopJS.ckAddress.addressListModalCanBeClose = false;
        });

        // confirm choose address
        shopJS.ckAddress.addressBtnChooseConfirm.on('click', function () {
            let $addressChoose = $('.ri-checkbox-circle-fill');
            let $messageBox = $('.choose-address-message');
            $messageBox.addClass('hide');
            let $addressBox = $addressChoose.parents('.address-show');
            let rid = $addressBox.data('rid');
            $u.http.post('/checkout/address/change', {
                rid: rid
            }).then((res) => {
                let result = res.data;
                if(result.code === 200){
                    window.location.reload();
                }else{
                    $messageBox.html(result.data.message);
                    $messageBox.removeClass('hide');
                }
            }).catch(
                (err) => {
                    $messageBox.html("server error");
                    $messageBox.removeClass('hide');
                });
        });
    },

    initAddressForm: function(){

        $(".js-country-list").on("change", function () {
            shopJS.ckAddress.ajaxCountryChange($(this))
        });

        /* init province, run once*/
        shopJS.ckAddress.ajaxCountryChange($('.js-country-list'), function () {
            const $pid = $('.addressData .cookieAddress_delivery_pid');
            const $province = $('.addressData .cookieAddress_delivery_province');
            if ($pid.length > 0 && $pid.data('value')) {
                shopJS.ckAddress.addressForm.find('#province-id').val($pid.data('value'));
            } else if ($province.length > 0 && $province.data('value')) {
                shopJS.ckAddress.addressForm.find('#province').val($province.data('value'));
            }
        });

        const $addressInput = shopJS.ckAddress.addressForm.find('#address');
        $addressInput.on('blur', function () {
            if ($addressInput.val().trim() !== '') {
                $addressInput.removeClass('isSearch')
            } else {
                $addressInput.addClass('isSearch')
            }
        }).on('focus', function () {
            $addressInput.addClass('isSearch')
        }).trigger('blur');

        // save address
        shopJS.ckAddress.addressBtnSave.on('click', function (e) {
            e.preventDefault();
            if (shopJS.ckAddress.addressInputCheck()) {
                shopJS.ckAddress.ajaxAddressSave();
            }
        });
    },

    resetAddressListEvents: function(){
        // address list choose
        $(".js-address-choose").off('click').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            let $icon =  $(this).find('i');

            // first remove all choose
            $('.ri-checkbox-circle-fill')
                .removeClass('ri-checkbox-circle-fill')
                .addClass('ri-checkbox-blank-circle-line')

            // then add choose
            $icon.removeClass('ri-checkbox-blank-circle-line');
            $icon.addClass('ri-checkbox-circle-fill');
        });

        // address edit
        $(".js-address-edit").off('click').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            let addressJson = $(this).parents('.address-show').data("addressjson");
            // console.log(addressJson)
            shopJS.ckAddress.setAddressInput(addressJson);
            shopJS.ckAddress.addressModalShow();
            shopJS.ckAddress.addressListModalCanBeClose = false;
        });
    },

    resetAddressShowEvents: function(){
        $(".address-show").off('click').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            let addressJson = $(this).data("addressjson");
            // console.log(addressJson)
            shopJS.ckAddress.setAddressInput(addressJson);
            shopJS.ckAddress.addressModalShow();
            shopJS.ckAddress.addressListModalCanBeClose = false;
        });
    },

    loadGoogleMapJs() {
        // console.log('load google map');
        (function (w, d, key, cb) {
            let url = 'https://maps.googleapis.com/maps/api/js';
            let f = d.getElementsByTagName('script')[0];
            let j = d.createElement('script');
            j.defer = true;
            j.src = url + '?key=' + key + '&callback=' + cb + '&libraries=places';
            f.parentNode.insertBefore(j, f);
        })(window, document, 'AIzaSyDpgIXIEFYsXvf2QjarE_rELq6kflxZyls', 'initGoogleMap');

        window.initGoogleMap = shopJS.ckAddress.initGoogleMap;
    },

    initGoogleMap() {
        // console.log('google call back');
        const addressInput = document.getElementById("address");
        const options = {
            componentRestrictions: {country: shopJS.ckAddress.countryCode || 'us'},
            fields:                ["address_components"],
            types:                 ["address"],
            strictBounds:          false,
        };

        shopJS.ckAddress.autoCompleteOfMaps = new window.google.maps.places.Autocomplete(addressInput, options);
        shopJS.ckAddress.autoCompleteOfMaps.addListener("place_changed", () => {
            const place = shopJS.ckAddress.autoCompleteOfMaps.getPlace();
            // console.log('place changed Event.')
            // console.log(place)
            let address = [];
            place.address_components.forEach((i) => {
                console.log(i);
                switch (i.types[0]) {
                    case shopJS.ckAddress.ACResultTypesMap.city[0]:
                        shopJS.ckAddress.addressForm.find('#city').val(i.long_name).siblings('.is-error').text('');
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.postal_code[0]:
                        shopJS.ckAddress.addressForm.find('#postal').val(i.long_name).siblings('.is-error').text('');
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.address[0]:
                        address[0] = i.long_name;
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.address[1]:
                        address[1] = i.long_name;
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.address[2]:
                        address[2] = i.long_name;
                        break;
                    case shopJS.ckAddress.ACResultTypesMap.province[0]:
                        if (shopJS.ckAddress.provinces.length > 0) {
                            let arr = shopJS.ckAddress.provinces.filter(j => {
                                return j.province_name === i.long_name;
                            })

                            if (arr.length > 0) {
                                shopJS.ckAddress.addressForm.find('#province-id').val(arr[0].pid)
                                    .siblings('.is-error').text('');
                                return;
                            }
                        }

                        shopJS.ckAddress.addressForm.find('#province').val(i.long_name).siblings('.is-error').text('');
                        break;
                    default:
                        break;
                }
            })

            shopJS.ckAddress.addressForm.find('#address').val(address.filter(i => !!i.trim()).join(' ')).siblings('.is-error').text('');
        });
    },

    getAddress: function () {
        return shopJS.ckAddress.address;
    },

    setAddress: function (address) {
        shopJS.ckAddress.address = address;
    },

    setCountryForMaps() {
        shopJS.ckAddress.countryCode = $('#country-id option:selected').data('code').toLowerCase();

        if (!shopJS.ckAddress.autoCompleteOfMaps) {
            return;
        }
        shopJS.ckAddress.autoCompleteOfMaps.setComponentRestrictions({
            country: [shopJS.ckAddress.countryCode],
        });
    },

    addressModalShow: function(action="updateList") {
        shopJS.ckAddress.addressEditModal.modal("show");
        shopJS.ckAddress.addressEditModal.data('action', action);
        shopJS.ckAddress.autocomplete && shopJS.ckAddress.initGoogleMap();
    },

    getAddressInputVal: function () {
        let delivery_province = '';
        let delivery_pid = 0;
        if(shopJS.ckAddress.addressInput.deliveryProvince.hasClass('hide')){
            delivery_province =  $.trim($('#province-id option:selected').text());
            delivery_pid = shopJS.ckAddress.addressInput.deliveryPid.val();
        }else{
            delivery_province = $.trim(shopJS.ckAddress.addressInput.deliveryProvince.val());
        }
        var address = {
            type:                $.trim(shopJS.ckAddress.addressInput.type.val()),
            delivery_first_name: $.trim(shopJS.ckAddress.addressInput.deliveryFirstName.val()),
            delivery_last_name:  $.trim(shopJS.ckAddress.addressInput.deliveryLastName.val()),
            delivery_address:    $.trim(shopJS.ckAddress.addressInput.deliveryAddress.val()),
            delivery_city:       $.trim(shopJS.ckAddress.addressInput.deliveryCity.val()),
            delivery_cid:        shopJS.ckAddress.addressInput.deliveryCid.val(),
            delivery_pid:        delivery_pid,
            delivery_province:   delivery_province,
            delivery_postcode:   $.trim(shopJS.ckAddress.addressInput.deliveryPostcode.val()),
            delivery_mobile:     $.trim(shopJS.ckAddress.addressInput.deliveryMobile.val()),
            delivery_email:      $.trim(shopJS.ckAddress.addressInput.deliveryEmail.val()),
        };

        // set rid
        let rid = $.trim(shopJS.ckAddress.addressInput.rid.val());
        if(rid !== '0'){
            address.rid = rid;
        }
        return address;
    },

    setAddressInput: function (address) {
        let addrInput = shopJS.ckAddress.addressInput;
        addrInput.rid.val(address && address.rid ? address.rid : 0);
        addrInput.deliveryFirstName.val(address && address.delivery_first_name ? address.delivery_first_name : '');
        addrInput.deliveryLastName.val(address && address.delivery_last_name ? address.delivery_last_name : '');
        addrInput.deliveryAddress.val(address && address.delivery_address ? address.delivery_address : '');
        addrInput.deliveryCity.val(address && address.delivery_city ? address.delivery_city : '');
        addrInput.deliveryPostcode.val(address && address.delivery_postcode ? address.delivery_postcode : '');
        addrInput.deliveryMobile.val(address && address.delivery_mobile ? address.delivery_mobile : '');
        addrInput.deliveryEmail.val(address && address.delivery_email ? address.delivery_email : '');

        let cid = address && address.delivery_cid ? address.delivery_cid : 1;
        let pid = address && address.delivery_pid ? address.delivery_pid : null;
        let province = address && address.delivery_province ? address.delivery_province : '';
        addrInput.deliveryCid.val(cid);
        /* update country && province */
        shopJS.ckAddress.ajaxCountryChange($('.js-country-list'), () => {
            if (province) {
                shopJS.ckAddress.addressForm.find('#province').val(province);
            }
            if (pid) {
                shopJS.ckAddress.addressForm.find('#province-id').val(pid);
            }
        });

    },

    addressInputCheck: function () {
        var address = shopJS.ckAddress.getAddressInputVal();
        var checkSet = {
            first_name: 'required',
            last_name:  'required',
            address:    'required',
            city:       'required',
            country:    'required',
            post_code:  'required',
            mobile:     'required',
            email:      'required|email'
        };

        if (shopJS.ckAddress.addressForm.autoCheckForm(checkSet).check()) {
            var $provinceSp = $('.sp_province');
            let hasProvinceIdCountries = ['United States', 'Canada'];
            let countrySelected = $('#country-id').find("option:selected").text();
            if (!address.delivery_pid && !address.delivery_province &&
                $.inArray(countrySelected, hasProvinceIdCountries) !== -1) {
                $provinceSp.html('please select or input you province');
                $provinceSp.css('display', 'inline');
                return false;
            } else {
                $provinceSp.html('');
                $provinceSp.css('display', 'none');
                return true;
            }
        }
        return false;
    },

    updateSelectedAddress: function(address){
        let rid = address.rid;
        let selectedAddress = $(".js-address-selected")
        let $addressShowContainer = selectedAddress.find('.address-show');
        let addressContent = shopJS.ckAddress.formatBillingAddress(address)
        let billingAddressTips = $(".billing-address-tips");

        if($addressShowContainer.length === 0){
            $addressShowContainer = $('<div class="address-show" data-rid="'+ rid +'"></div>');
            selectedAddress.prepend($addressShowContainer);
        }

        $addressShowContainer.data('addressjson', address)
        $addressShowContainer.html(addressContent);

        selectedAddress.removeClass('hide');
        billingAddressTips.removeClass('hide');
        shopJS.ckAddress.resetAddressShowEvents();
        shopJS.payment.initBillingAddress();
    },

    // when add address or update one address for address list, need update address list
    updateAddressListAddress: function (address){
        let rid = address.rid;
        let $addressShowContainer = shopJS.ckAddress.addressList.find('.address-show[data-rid=' + rid + ']');
        let addressContent = shopJS.ckAddress.formatAddressShow(address)

        // console.log($addressShowContainer)
        if($addressShowContainer.length === 0){
            $addressShowContainer = $('<div class="address-show" data-rid="'+ rid +'"></div>');
            shopJS.ckAddress.addressList.prepend($addressShowContainer);
        }

        $addressShowContainer.data('addressjson',address)
        $addressShowContainer.html(addressContent);

        shopJS.ckAddress.resetAddressListEvents();
        // choose current address
        $addressShowContainer.find(".js-address-choose").trigger('click');
    },

    // create one address info by address data for update or create address
    formatAddressShow: function(address){
        return '' +
            '<div class="address-info">' +
            '   <ul>' +
            '       <li>' + address.delivery_first_name + ' ' + address.delivery_last_name + '</li>' +
            '       <li>' + address.delivery_address + ',' + address.delivery_province + ',' + address.delivery_country + ',' + address.delivery_postcode + '</li>' +
            '       <li>Phone: '+ address.delivery_mobile + '</li>' +
            '   </ul>' +
            '   <div class="address-edit js-address-edit">' +
            '       <i class="ri-edit-box-line"></i>' +
            '       <span>Edit</span>' +
            '   </div>' +
            '</div>' +
            '<div class="js-address-choose">' +
            '   <i class="ri-checkbox-blank-circle-line"></i>' +
            '</div>';
    },

    formatBillingAddress: function(address){
        return '<div class="address-info">' +
            '   <ul>' +
            '       <li>' + address.delivery_first_name + ' ' + address.delivery_last_name + '</li>' +
            '       <li>' + address.delivery_address + ',' + address.delivery_province + ',' + address.delivery_country + ',' + address.delivery_postcode + '</li>' +
            '       <li>Phone: ' + address.delivery_mobile + '</li>' +
            '   </ul>' +
            '</div>' +
            '<div class="address-edit js-address-edit">' +
                '<i class="ri-edit-box-line"></i><span style="font-size:12px">Edit</span>'+
            '</div>';
    },

    // save address, call api
    ajaxAddressSave: function () {
        // need check the form is in modal or not in modal
        let action = shopJS.ckAddress.addressForm.data('action')
        let address = shopJS.ckAddress.getAddressInputVal();
        let $addressErrorMessage = $('.address-message');

        $u.http.post('/api/userAddress', address).then(res => {
            if (res.status === 200) {
                let address = res.data;

                if(action === "reload"){
                    location.reload();
                }

                if(action === 'update'){
                    // update address list
                    shopJS.ckAddress.updateAddressListAddress(address);
                    shopJS.ckAddress.addressEditModal.modal('hide');
                }

                if(action === 'set-selected'){
                    shopJS.ckAddress.updateSelectedAddress(address);
                    shopJS.ckAddress.addressEditModal.modal('hide');
                }
            }else{
                $addressErrorMessage.html("request data error");
                $addressErrorMessage.removeClass('hide');
                setTimeout(function(){
                    $addressErrorMessage.addClass('hide');
                },2000);
            }
        }).catch(err => {
            console.log(err)
            $addressErrorMessage.html("server error");
            $addressErrorMessage.removeClass('hide');
            setTimeout(function(){
                $addressErrorMessage.addClass('hide');
            },2000);
        })
    },

    ajaxCountryChange: function ($element) {
        var callback = arguments[1] ? arguments[1] : null;
        var cid = $element.val();

        shopJS.ckAddress.setCountryForMaps()

        $u.http.post('/api/checkout/getProvince', {
            delivery_cid: cid
        }).then(res => {
            let result = res.data;
            if (result.code === 200 && result.data.success) {
                shopJS.ckAddress.provinces = result.data.provinces;
                shopJS.ckAddress.addressInput.deliveryPid.find('option').remove();
                if (result.data.provinces.length === 0) {
                    shopJS.ckAddress.addressInput.deliveryProvince.val('');
                    shopJS.ckAddress.addressInput.deliveryProvince.removeClass('hide');
                    $('.js-province-list').addClass('hide');
                } else {
                    shopJS.ckAddress.addressInput.deliveryProvince.addClass('hide');

                    const $pIdList = shopJS.ckAddress.addressInput.deliveryPid;
                    $pIdList.append($('<option value="" selected>Please select...</option>'));
                    $.each(result.data.provinces, function (index, province) {
                        $pIdList.append($('<option value="' + province.pid + '">' + province.province_name + '</option>'));
                    });

                    $('.js-province-list').removeClass('hide');
                }

                if (callback && typeof callback == 'function') {
                    callback(result.data);
                }
            }
        }).catch()
    }
};